





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DownloadAction from "./DownloadAction.vue";

@Component({
  components: { DownloadAction }
})
export default class DownloadDialog extends Vue {
  @Prop({ type: String, required: true }) reportId!: string;
  @Prop({ type: String, required: true }) conservatorship!: string;
  @Prop({ type: Boolean, required: true }) financialAdmin!: boolean;

  dialog: boolean = false;
  disabled: boolean = false;

  value: number[] = [6, 11, 14];


  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = [6, 11, 14];
    }
  }
}
