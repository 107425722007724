














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { FETCH_REPORTS, GET_REPORTS } from '@/modules/reportOfConservatorshipManagement/store';
import { Report } from '@/modules/reportOfConservatorshipManagement/types';
import AppTable from '@/components/AppTable.vue';
import DownloadDialog from "@/modules/reportOfConservatorshipManagement/components/DownloadDialog.vue";

const Report = namespace('reportOfConservatorshipManagement');

@Component({ components: { DownloadDialog, AppTable } })
export default class ConservatorshipReportPlugin extends Vue {
  @Prop({ type: String }) conservatorship!: string;
  @Prop({ type: Boolean }) financialAdmin!: boolean;

  @Report.Getter(GET_REPORTS) findByConservatorship!: (conservatorshipId: string) => Report[];
  @Report.Action(FETCH_REPORTS) fetch!: (conservatorshipId: string) => Promise<ApiResponse>;

  loading: boolean = false;

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.year'), value: 'year' },
      { align: 'left', text: this.$i18n.t('common.created'), value: 'created' },
      { align: 'left', text: this.$i18n.t('common.actions'), sortable: false }
    ];
  }

  get reports(): Report[] {
    return this.findByConservatorship(this.conservatorship);
  }

  created() {
    return this.fetch(this.conservatorship);
  }
}
