















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_REPORT_PDF } from '../store';
import { ApiResponse } from '@/components/types';
import { GET_DETAILS } from '@/modules/conservatorship/store/getters';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';

const Report = namespace('reportOfConservatorshipManagement');
const Conservatorship = namespace('conservatorship');

@Component({})
export default class DownloadAction extends Vue {
  @Prop({ type: String, required: true }) uuid!: string;
  @Prop({ type: String, required: true }) reportId!: string;
  @Prop({ type: String, default: 'primary' }) color!: string;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: true }) icon!: boolean;
  @Prop({ type: String }) label?: string;
  @Prop({ type: Array }) breaks?: number[];

  @Report.Action(FETCH_REPORT_PDF) fetchPDF!: ({ conservatorship_id, report_id }: { conservatorship_id: string, report_id: string, breaks?: number[] }) => Promise<ApiResponse<Blob>>;
  @Conservatorship.Getter(GET_DETAILS) getDetails!: (id: string) => ConservatorshipDetails | undefined;

  loading: boolean = false;
  error: any = null;

  get conservatorship() {
    return this.getDetails(this.$route.params['id']);
  }

  get filename(): string {
    if (!this.conservatorship) {
      return `Bericht-zur-Fuehrung-der-Betreuung.pdf`;
    }

    return `Bericht-${this.conservatorship.conservatee.surname}-${this.conservatorship.conservatee.forename}.pdf`;
  }

  async download() {
    this.error = null;
    this.loading = true;

    const { content, error } = await this.fetchPDF({ conservatorship_id: this.uuid, report_id: this.reportId, breaks: this.breaks });

    if (error) {
      this.error = error;
      this.loading = false;
      return;
    }

    this.loading = false;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', this.filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
